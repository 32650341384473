<template>
  <LoadingWrapper :loading="loading">
    <div v-if="data">
      <DlgHeader>
        <IxRes>dlgCert.social.safety.headers.employeeSurvey</IxRes>
      </DlgHeader>
      <CheckboxLine :data="data" name="surveysConducted" @input="update" />
      <DlgHeader>
        <IxRes>dlgCert.social.safety.headers.trainingAndGuidance</IxRes>
      </DlgHeader>
      <CheckboxLine :data="data" name="trainingsAbsolved" @input="update" />
      <DlgHeader>
        <IxRes>dlgCert.social.safety.headers.riskEvaluation</IxRes>
      </DlgHeader>
      <p>
        <IxRes>dlgCert.social.safety.downloadDocs</IxRes>
      </p>
      <IxRes>dlgCert.social.safety.riskEvaluationExplanation</IxRes>
      <br>
      <b>
        <IxRes>dlgCert.social.safety.headers.riskEvaluationImportantNote</IxRes>
      </b>
      <div class="forms-wrapper">
        <div class="form-row">
          <div>
            <i class="fa fa-file-text-o" />
            &nbsp;
            <IxRes>dlgCert.social.safety.grid.guidelineQuestionnaire</IxRes>
          </div>
          <div>
            <IxButton
              class="upload-file-btn" icon="download"
              rounded @click="downloadFile('instructionsQuestionnaireSVLFG')"
            >
              <IxRes>dlgCert.social.buttons.downloadGuideline</IxRes>
            </IxButton>
          </div>
        </div>
        <div class="form-row">
          <div>
            <i class="fa fa-file-archive-o" />
            &nbsp;
            <IxRes>dlgCert.social.safety.grid.downloadAsZIP</IxRes>
          </div>
          <div>
            <IxButton
              class="upload-file-btn btn-primary" icon="download"
              rounded colored
              @click="downloadAllForms()"
            >
              <IxRes>dlgCert.social.buttons.downloadAllForms</IxRes>
            </IxButton>
          </div>
        </div>
        <div v-for="(form) in forms" :key="form.name" class="form-row">
          <div class="name-and-status-column">
            <span :class="[form.uploadedOn && form.file ? 'success' : 'fail']">
              <i :class="[form.uploadedOn && form.file ? 'fa fa-check-circle' : 'fa fa-times-circle']" />
            </span> &nbsp;
            <DlgInputDescription :name="form.name" />
          </div>
          <div class="form-download-column">
            <IxButton
              class="download-file-btn" icon="download"
              rounded @click="downloadFile(form.name)"
            >
              <IxRes>dlgCert.social.buttons.downloadForm</IxRes>
            </IxButton>
          </div>
          <div class="form-upload-column">
            <IxButton
              class="upload-file-btn" :class="[form.file ? 'btn-secondary custom-colored-btn' : 'btn-primary']"
              icon="upload"
              rounded @click="openFileSelection(form.name)"
            >
              <IxRes>{{ `dlgCert.social.buttons.${form.file ? 'uploadNewerForm' : 'uploadForm'}` }}</IxRes>
            </IxButton>
          </div>
          <div class="file-display-column">
            <div v-if="form.file" class="file-display-cell">
              <span style="font-style: italic">
                {{ form.file }}
              </span>
              &nbsp;
              <IxRes>dlgCert.social.grid.uploadedOn</IxRes> &nbsp; {{ form.uploadedOn }}
              <IxButton
                rounded class="remove-file-btn"
                icon="trash" @click="removeForm(form)"
              />
            </div>
          </div>
        </div>
      </div>
      <input
        ref="fileInput" type="file"
        name="file" class="fileinput"
        accept=".doc, .docx" @change="uploadForm"
      >
      <a
        ref="download"
        data-ajax="false" href=""
        download style="display: none"
      />
    </div>
  </LoadingWrapper>
</template>

<script>
import moment from 'moment'
import IxButton from '@components/IxButton'

import LoadingWrapper from './shared/LoadingWrapper'

import DlgInputDescription from './elements/DlgInputDescription'
import DlgHeader from './elements/DlgHeader'
import CheckboxLine from './elements/CheckboxLine'

import ApiMixin from '@dlg-cert/mixins/ApiMixin'

export default {
  components: {
    CheckboxLine,
    DlgHeader,
    DlgInputDescription,
    IxButton,
    LoadingWrapper
  },
  mixins: [
    ApiMixin
  ],
  data () {
    return {
      currentForm: null
    }
  },
  computed: {
    apiUrl () {
      return `/api/v2/dlg-cert/${this.$route.params.orgUnitId}/social/${this.$route.params.year}/safety`
    },
    forms () {
      return this.data.forms
    }
  },
  methods: {
    downloadFile (fileName) {
      this.$refs.download.href = this.apiUrl + `/file/${fileName}`
      this.$refs.download.click()
    },
    downloadAllForms () {
      this.$refs.download.href = this.apiUrl + '/form/all'
      this.$refs.download.click()
    },
    openFileSelection (formName) {
      this.currentForm = formName
      this.$refs.fileInput.click()
    },
    async uploadForm (form) {
      let file = form.target.files[0]
      let uploadedOn = moment().format('L')
      let formData = new FormData()
      formData.append('formFile', file)
      formData.append('formName', this.currentForm)
      formData.append('uploadedOn', uploadedOn)
      try {
        await this.api.post('/form/upload', formData, {headers: {'Content-Type': 'multipart/form-data'}})
        .then(() => {
          let currentForm = this.forms.find(f => f.name === this.currentForm)
          currentForm.file = file.name
          currentForm.uploadedOn = uploadedOn
          this.$refs.fileInput.value = '' // workaround to trigger change event of file-input on chrome
        })
      } catch (error) {
        console.error(error)
        this.$refs.fileInput.value = '' // workaround to trigger change event of file-input on chrome
      }
    },
    async removeForm (form) {
      try {
        await this.api.post(`/form/${form.name}/remove`)
        .then(() => {
          this.forms.find(f => f === form).file = null
          this.forms.find(f => f === form).uploadedOn = null
        })
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.fileinput {
  display: none !important;
}

.custom-colored-btn {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.custom-colored-btn:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
}

.forms-wrapper {
  margin: 5px;

  .form-row {
    display: grid;
    gap: 5px;
    grid-template-columns: 1fr 1fr 1fr 2fr;

    > * {
      padding: 0.25em 0.5em;
      display: flex;
      align-items: center;
    }

    .success {
      color: green;
    }
    .fail {
      color: red;
    }

    .file-display-cell {
      display: flex;
      flex-grow: 1;
    }

    .remove-file-btn {
      margin-left: auto;
    }
  }

  .form-row:nth-child(2n +3) {
    background-color: lighten(lightgray, 10%);
  }
}
</style>
